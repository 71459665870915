import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../components/fontawesome.css"
import Finalist from "../../components/finalist"

import LeftMenu from './leftMenu'
const SemiFinalists = () => (
  <Layout>
    <SEO title="National Student Essay Competition | Semi-Finalists" />

    <div className="subpageContainer">

      <LeftMenu active="semi-finalists" />
      <div className="body">
         
        <h1 style={{marginTop: '55px'}}>NATIONAL STUDENT ESSAY COMPETITION: WHY A FREE PRESS MATTERS IN A DEMOCRACY</h1>
        
        <h2>SEMI-FINALISTS</h2>

        <h3>UNIVERSITY </h3>

        <div className="finalist-group">
        <Finalist name="Emily Isbell" school="Belmont University" location="Nashville, Tennessee" />
        <Finalist name="Alexander Johansson" school="Snow College" location="Ephraim, Utah" />
        <Finalist name="Lewis Jones Jr." school="California State University" location="San Marcos, California" />
        <Finalist name="Rachelle Larsen" school="Brigham Young University" location="Provo, Utah" />
        <Finalist name="A.Z. Chance Martinez" school="Pima Community College" location="Tucson, Arizona" />
        <Finalist name="Breanna Russo" school="Morehead State University" location="Morehead, Kentucky" />
        <Finalist name="Ali Sullivan" school="University of Virginia" location="Charlottesville, Virginia" />
        <Finalist name="Rachel Wong" school="The City College of New York" location="New York City, New York" />
        </div>
        
        <h3>GRADES 9-12</h3>
        <div className="finalist-group">
        <Finalist name="Kinsey Ballard" school="North Bullitt High School, 11th Grade" location="Shepherdsville, Kentucky" />
        <Finalist name="Aden Barton" school="Montgomery Bell Academy, 12th Grade" location="Nashville, Tennessee" />
        <Finalist name="Annelise Best" school="Frisco Liberty High School, 11th Grade" location="Frisco, Texas" />
        <Finalist name="Josephine Decker" school="Catalina Foothills High School, 11th Grade" location="Tucson, Arizona" />
        <Finalist name="Daniel Fuentez" school="Sheboygan North High School, 11th Grade" location="Sheboygan, Wisconsin" />
        <Finalist name="Anya S. Grondalski" school="Shepherd Hill Regional High School, 12th Grade" location="Charlton, Massachusetts" />
        <Finalist name="Ian Gunelson" school="Cloquet High School, 11th Grade" location="Cloquet, Minnesota" />
        <Finalist name="Holton Hennrich" school="Juab High School, 12th Grade" location="Nephi, Utah" />
        <Finalist name="Samuel M. Kasprzak" school="Kasprzak Academy of Homeschool, 9th Grade" location="Glenbeulah, Wisconsin" />
        <Finalist name="Daniel Okstein" school="Sharon High School, 10th Grade" location="Sharon, Massachusetts" />
        <Finalist name="Arundhati Oommen" school="West High School, 10th Grade" location="Salt Lake City, Utah" />
        <Finalist name="Anika Rao" school="West High School, 9th Grade" location="Salt Lake City, Utah" />
        <Finalist name="Sophia Spiegel" school="Horace Greeley High School, 10th Grade" location="Chappaqua, New York" />
        </div>
        
        <h3>GRADES 6-8</h3>
        <div class="finalist-group">
        <Finalist name="Daisy Determan" school="Basis Tucson North, 7th Grade" location="Tucson, Arizona" />
        <Finalist name="Marcus Lewis, Jr." school="Rodeo Palms JH, 7th grade" location="Rosharon, Texas 77583" />
        <Finalist name="Evelyn Link" school="Hawthorne Elementary, 6th Grade" location="Salt Lake City, Utah" />
        <Finalist name="Eric Lu" school="West High School, 8th Grade" location="Salt Lake City, Utah" />
        <Finalist name="Frank Connor Runyon" school="Richview Middle School, 7th Grade" location="Clarksville, Tennessee" />
        <Finalist name="Evelyn Sampson" school="Ephraim Curtis Middle School, 8th Grade" location="Sudbury, Massachusetts" />
        <Finalist name="Sofia D. Sileo" school="St. Michael Catholic School, 7th Grade" location="Louisville, Kentucky" />
        <Finalist name="Livie Stader" school="Oostburg Middle School, 8th Grade" location="Cedar Grove, Wisconsin" />
        <Finalist name="Maddie Stone" school="Fox Lane Middle School, 6th Grade" location="Mt. Kisco, New York" />
        <Finalist name="Avary Wennergren" school="Dillworth Elementary, 6th Grade" location="Salt Lake City, Utah" />
        </div>

      </div>
    </div>

    

  </Layout>
)

export default SemiFinalists
